import "./libs/0-loadCSS.js";
import "./libs/0ff-supports.js";
import "./libs/0plugins.js";
import "./libs/0polyfills.js";
import "./libs/1ff-class.js";
import "./libs/1ffQuery.js";
import "./libs/2ffQuery-ajax.js";
import "./libs/2ffQuery-insertAfter.js";
// import "./libs/2ffQuery-nextUntil.js";
import "./libs/2ffQuery-nextAll.js";
import "./libs/2ffQuery-parseHTML.js";
// import "./libs/2ffQuery-siblings.js";
import * as anime from "./libs/3anime.min.js";
// import Flickity from "./libs/3flickity.pkgd.min.js";
import Flickity from "flickity";
import "flickity-fade";
// import "./libs/4flickity-fade.js";
import cookies from "./libs/5cookies.min.js";

(function (window) {
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Variables
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var w = window,
    d = document,
    html = d.documentElement,
    body = d.body,
    header = $("#header"),
    header_h = header ? header.clientHeight : 0,
    footer = $("#footer"),
    main = $("#main"),
    subHeader = $("[data-fixed]", main),
    subHeader_container = subHeader
      ? subHeader.closest("[data-fixed-container]")
      : false,
    // nav_h,
    subHeader_h = subHeader ? subHeader.clientHeight : 0,
    windowH,
    windowW,
    scrollT,
    scrollUp = false,
    sauvScroll = 0,
    serveur_test = d.location.hostname == "localhost",
    adminBar = $("#wpadminbar"),
    has_adminBar = ffclass.has(body, "admin-bar"),
    adminBar_h = 0,
    is_requeting = false;

  cookies.expires = 0; //30 * 24 * 3600
  cookies.domain = url.cookie_domain;
  cookies.path = url.cookie_path;
  // cookies.secure = w.location.protocol === 'https:';
  // cookies.autojson = false;

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Dimensions
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function update_dimensions() {
    windowH = w.innerHeight;
    windowW = w.innerWidth;
    adminBar = !adminBar && has_adminBar ? $("#wpadminbar") : adminBar;
    adminBar_h = adminBar ? adminBar.innerHeight : 0;
    // subHeader_h = subHeader ? subHeader.innerHeight : 0;
    // nav_h = header ? header.innerHeight : 0;
    update_scroll();
  }
  function update_scroll() {
    if (w.pageYOffset < scrollT) {
      scrollUp = true;
    } else {
      scrollUp = false;
    }
    scrollT = w.pageYOffset;
  }
  update_dimensions();

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Helper functions
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Easing functions (inspired by http://gizma.com/easing/)
  var easing = {
    linear: function (t) {
      return t;
    },
    easeInQuad: function (t) {
      return t * t;
    },
    easeOutQuad: function (t) {
      return t * (2 - t);
    },
    easeInOutQuad: function (t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    },
    easeInCubic: function (t) {
      return t * t * t;
    },
    easeOutCubic: function (t) {
      return --t * t * t + 1;
    },
    easeInOutCubic: function (t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    },
    easeInQuart: function (t) {
      return t * t * t * t;
    },
    easeOutQuart: function (t) {
      return 1 - --t * t * t * t;
    },
    easeInOutQuart: function (t) {
      return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
    },
    easeInQuint: function (t) {
      return t * t * t * t * t;
    },
    easeOutQuint: function (t) {
      return 1 + --t * t * t * t * t;
    },
    easeInOutQuint: function (t) {
      return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
    },
  };

  //ScrollTo animate
  function scrollTo(Y, duration, easingFunction, callback) {
    var start = Date.now(),
      from = window.pageYOffset;

    if (from === Y) {
      if (callback) callback();
      return;
    }

    if (!easingFunction) easingFunction = easing.linear;

    function scroll(timestamp) {
      var currentTime = Date.now(),
        time = Math.min(1, (currentTime - start) / duration),
        easedT = easingFunction(time);

      yPos = easedT * (Y - from) + from;
      window.scrollTo(0, yPos);

      if (time < 1) requestAnimationFrame(scroll);
      else if (callback) callback();
    }

    requestAnimationFrame(scroll);
  }

  //Leadding zeros
  function pad(num, size) {
    var s = "000000000" + num;
    return s.substr(s.length - size);
  }

  //script async
  function async(script, callback) {
    var s = d.createElement("script"),
      t = d.getElementsByTagName("script")[0],
      r = false;
    // head = d.getElementsByTagName('head')[0];
    s.type = "text/javascript";
    // s.async = true;
    s.src = script;
    // if (typeof callback == 'function') { s.addEventListener('load', function (e) { callback.call(); }, false); }
    if (typeof callback == "function") {
      s.onload = s.onreadystatechange = function () {
        //console.log( this.readyState ); //uncomment this line to see which ready states are called.
        if (!r && (!this.readyState || this.readyState == "complete")) {
          r = true;
          callback();
        }
      };
    }
    // head.appendChild(s);
    t.parentNode.insertBefore(s, t);
  }

  //Url décode
  function urldecode(str) {
    return decodeURIComponent((str + "").replace(/\+/g, "%20"));
  }

  //Array chunk
  function array_chunk(array, chunk) {
    var i,
      j,
      temparray,
      array_return = [];
    chunk = chunk || 3;
    for (i = 0, j = array.length; i < j; i += chunk) {
      array_return.push(array.slice(i, i + chunk));
    }
    return array_return;
  }

  //Debounce
  function debounce(fn, wait) {
    var timeout;
    return function () {
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        fn.apply(this, arguments);
      }, wait || 1);
    };
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Supports
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  // if(!support.objectFit) ffclass.add(html,'no-objectfit');
  // if(!support.objectPosition) ffclass.add(html,'no-objectposition');
  fallback_object_fit();

  function fallback_object_fit(cont) {
    if (support.objectFit) return;

    var fits = $$("img.cv-img:not(.srOnly)", cont || body);
    fits.forEach(function (fit, idx) {
      // var fitClass = ffclass.has(fit,'cv-contain') ? 'cv-bg-contain' : 'cv-bg';
      var srcFit = fit.hasAttribute("src") ? fit.getAttribute("src") : false;
      if (!srcFit)
        srcFit = fit.hasAttribute("data-src")
          ? fit.getAttribute("data-src")
          : false;
      if (!srcFit)
        srcFit = fit.hasAttribute("data-flickity-lazyload")
          ? fit.getAttribute("data-flickity-lazyload")
          : false;
      if (!srcFit) return;
      var replaceFit = d.createElement("div");
      replaceFit.className = fit.className;
      // replaceFit.className = fit.className + ' ' + fitClass;
      // ffclass.remove(replaceFit,'cv-img');
      // ffclass.remove(replaceFit,'cv-contain');
      replaceFit.style.backgroundImage = "url(" + srcFit + ")";
      fit.parentNode.appendChild(replaceFit);
      ffclass.add(fit, "srOnly");
      // ffclass.remove(fit,'cv-img');
    });
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Scroll arrivé sur la page
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var scrollerToElement = $(".js-to-scroll", body);
  if (scrollerToElement) {
    scroll_to_target(scrollerToElement);
  }

  function scroll_to_target(el) {
    var scrollVal =
      el.getBoundingClientRect().top + scrollT - subHeader_h * 2 - 32;
    if (Math.abs(scrollT - scrollVal) < 3) return;
    isScrolling = true;
    scrollTo(scrollVal, 500, easing.easeInOutCubic, function () {
      isScrolling = false;
    });
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Menu mobile
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var menuTrigger = $("#menuTrigger");
  var idMenuTimeOut = false;

  if (menuTrigger) {
    menuTrigger.addEventListener("click", menu_trigger, false);
  }

  function menu_trigger(event) {
    event.preventDefault();
    if (ffclass.has(html, "has-menuOpen")) {
      menu_close();
    } else {
      // scrollTo(0, 100, easing.linear, function() {
      //   menu_open();
      // });
      menu_open();
    }
  }

  function menu_close() {
    if (idMenuTimeOut) w.clearTimeout(idMenuTimeOut);
    if (!ffclass.has(html, "has-menuOpen")) return;
    ffclass.remove(html, "is-scrollBlocked");
    // w.scrollTo(0, 0);
    w.scrollTo(0, sauvScroll);
    w.setTimeout(function () {
      ffclass.remove(html, "has-menuOpen");
      ffclass.remove(menuTrigger, "is-active");
    }, 50);
  }

  function menu_open() {
    sauvScroll = scrollT;
    idMenuTimeOut = w.setTimeout(function () {
      ffclass.add(html, "is-scrollBlocked");
    }, 300);
    ffclass.add(menuTrigger, "is-active");
    ffclass.add(html, "has-menuOpen");
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Accordéons
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  const accordions = document.querySelectorAll(".acc");

  const openAccordion = (accordion) => {
    const content = accordion.querySelector(".acc_content");
    accordion.classList.add("acc_active");
    content.style.maxHeight = content.scrollHeight + "px";
  };

  const closeAccordion = (accordion) => {
    const content = accordion.querySelector(".acc_content");
    accordion.classList.remove("acc_active");
    content.style.maxHeight = null;
  };

  accordions.forEach((accordion) => {
    const head = accordion.querySelector(".acc_head");
    const content = accordion.querySelector(".acc_content");

    head.onclick = () => {
      if (content.style.maxHeight) {
        closeAccordion(accordion);
      } else {
        accordions.forEach((accordion) => closeAccordion(accordion));
        openAccordion(accordion);
      }
    };
  });

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Accordéons mobiles
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var accsMobile = $$(".js-acc-mobile", main);
  function accsMobile_init(cont) {
    var accsMobile = $$(".js-acc-mobile:not([data-js-init])", cont || main);
    if (!accsMobile.length) return;

    accsMobile.forEach(function (item) {
      item.addEventListener("click", accsMobile_trigger, false);
    });
  }
  accsMobile_init();

  function accsMobile_trigger(event) {
    event.preventDefault();
    var is_untoggle = this.hasAttribute("data-untoggle");
    var container_toggle = this.closest("[data-acc-mobile-cont]");
    var acc_parent = this.parentNode;
    var is_scrollTo = this.hasAttribute("data-acc-scroll");
    if (is_untoggle) {
      this.removeEventListener("click", accsMobile_trigger, false);
      this.remove();
    } else if (container_toggle) {
      accsMobile_closeAll(container_toggle, acc_parent);
    }
    ffclass.toggle(acc_parent, "is-open-mobile");
    if (is_scrollTo && ffclass.has(acc_parent, "is-open-mobile")) {
      scroll_to_target(acc_parent);
    }
  }

  function accsMobile_closeAll(container_toggle, acc_actual) {
    var accsMobileOpen = $$(".is-open-mobile", container_toggle).filter(
      function (item) {
        return acc_actual !== item;
      }
    );
    ffclass.remove(accsMobileOpen, "is-open-mobile");
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Nav slide
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function nav_slide_update() {
    if (!ffclass.has(html, "has-menuOpen") && !scrollUp && scrollT > 0)
      ffclass.add(html, "is-nav-slided");
    else ffclass.remove(html, "is-nav-slided");
  }
  nav_slide_update();

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Filtres
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var filters = $(".pSubMenu", main);
  var filters_inner = filters ? $(".pSubMenu_inner", filters) : false;
  var filters_h = filters_inner ? filters_inner.offsetHeight : 0;
  var filters_items = filters_inner ? $$(".filters_i", filters_inner) : [];
  var filters_triggers = filters_inner
    ? $$(".filters_trigger", filters_inner)
    : [];
  var filters_subMenuTrigger = filters_inner
    ? $(".pSubMenu_trigger", filters_inner)
    : false;
  var filterReset = $(".filters_i-reset .filters_active", body);
  var filters_menu = $(".pSubMenu_list", filters_inner);

  function filters_init(container) {
    if (filters_triggers.length) {
      filters_triggers.forEach(function (item) {
        item.addEventListener("click", filter_triggering, false);
      });
    }
    if (filters_subMenuTrigger) {
      filters_subMenuTrigger.addEventListener(
        "click",
        filter_triggering_mobile,
        false
      );
    }
  }
  filters_init();

  function filter_triggering(event) {
    // var parent = this.closest(".filters_active");
    // if (ffclass.has(parent, "has-active")) return;
    event.preventDefault();
    var trigger = this;
    var item = this.closest(".filters_i");
    var itemOpen = $(".filters_i.is-open", filters_inner);
    var subMenu = $(".filters_list", item);
    if (ffclass.has(item, "is-open")) {
      //Fermeture
      filters_close();
      ajxFilters_update();
    } else if (itemOpen) {
      //Changement
      anime({
        targets: filters_inner,
        height: subMenu.offsetHeight + filters_h,
        duration: 250,
        easing: "easeInOutQuad", //'linear'
        begin: function (anim) {
          filters_reset_height();
          ffclass.remove(itemOpen, "is-open");
          ffclass.add(item, "is-open");
        },
        complete: function (anim) {
          filters_update_height();
        },
      });
    } else {
      //Ouverture
      anime({
        targets: filters_inner,
        height: subMenu.offsetHeight + filters_h,
        duration: 250,
        easing: "easeInOutQuad", //'linear'
        begin: function (anim) {
          filters_reset_height();
          ffclass.add(filters_menu, "menu-is-open");
          ffclass.add(item, "is-open");
          ffclass.remove(filterReset, "is-active");
        },
        complete: function (anim) {
          filters_update_height();
        },
      });
    }
  }

  function filters_reset_height() {
    if (subHeader && !ffclass.has(subHeader, "is-fixed")) {
      filters.removeAttribute("style");
    }
  }

  function filters_update_height(reset) {
    if (!subHeader) return;
    if (reset) {
      if (ffclass.has(subHeader, "is-fixed")) {
        w.scrollBy(0, filters_inner.offsetHeight - filters.offsetHeight);
      }
      filters.removeAttribute("style");
    } else {
      if (ffclass.has(subHeader, "is-fixed")) {
        w.scrollBy(0, filters_inner.offsetHeight - filters.offsetHeight);
      }
      filters.style.height = filters_inner.offsetHeight + "px";
    }
  }

  function filters_close() {
    anime({
      targets: filters_inner,
      height: filters_h,
      duration: 250,
      easing: "easeInOutQuad", //'linear'
      begin: function (anim) {
        filters_reset_height();
      },
      complete: function (anim) {
        ffclass.remove(filters_items, "is-open");
        ffclass.remove(filters, "is-subMenuOpen");
        ffclass.remove(filters_menu, "menu-is-open");
        filters_inner.removeAttribute("style");
        filters_update_height(true);
      },
    });
  }

  function filter_triggering_mobile(event) {
    event.preventDefault();
    var trigger = this;
    var subMenu = $(".pSubMenu_wrapper", filters_inner);
    if (ffclass.has(filters, "is-subMenuOpen")) {
      //Fermeture
      filters_close();
    } else {
      //Ouverture
      anime({
        targets: filters_inner,
        height: subMenu.offsetHeight + filters_h,
        duration: 250,
        easing: "easeInOutQuad", //'linear'
        begin: function (anim) {
          filters_reset_height();
          ffclass.add(filters, "is-subMenuOpen");
        },
        complete: function (anim) {
          filters_update_height();
        },
      });
    }
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Filtres en ajax
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var ajxFilter = $(".js-ajax-filters", main);
  var ajxFilterPostType =
    ajxFilter && ajxFilter.hasAttribute("data-post-type")
      ? ajxFilter.getAttribute("data-post-type")
      : "post";
  var ajxFilterLinks = ajxFilter ? $$("a", ajxFilter) : [];
  var ajxFilterSelect = $$(".filters select", main);
  var ajxFilterReset = ajxFilter ? $("[data-filters-reset]", ajxFilter) : false;
  var activesFilters = [];
  var searchInput = ajxFilter ? $("#search") : false;
  var reset = $(".filter_reset", main);
  var itemOpen = $(".filters_i.is-open", main);

  var loader = d.createElement("div");
  ffclass.add(loader, "has-loader");
  ffclass.add(loader, "has-loader-black");
  ffclass.add(loader, "is-loading");
  ffclass.add(loader, "txt-24");
  ffclass.add(loader, "loader");

  function ajxFilters_getActive() {
    var activeEls = $$("[data-active]", ajxFilter);
    activesFilters = [];

    //Recherche
    if (
      searchInput &&
      searchInput.hasAttribute("data-active-search") &&
      searchInput.getAttribute("data-active-search") != ""
    ) {
      activesFilters.push({
        search: searchInput.getAttribute("data-active-search"),
      });
    }

    //Filtres
    activeEls.forEach(function (el) {
      var activeParentItem = el.closest(".filters_i");
      var selectItem = activeParentItem ? $("select", activeParentItem) : false;
      if (
        el.hasAttribute("data-active-taxo") &&
        el.hasAttribute("data-active-id")
      ) {
        activesFilters.push({
          taxonomy: el.getAttribute("data-active-taxo"),
          id: parseInt(el.getAttribute("data-active-id")),
        });
        if (selectItem) {
          selectItem.value = el.getAttribute("data-active-id");
        }
      } else if (
        el.hasAttribute("data-date") &&
        el.hasAttribute("data-active-year") &&
        el.hasAttribute("data-active-month")
      ) {
        activesFilters.push({
          year: parseInt(el.getAttribute("data-active-year")),
          month: parseInt(el.getAttribute("data-active-month")),
        });
        if (selectItem) {
          selectItem.value =
            el.getAttribute("href") +
            el.getAttribute("data-active-year") +
            "/" +
            pad(el.getAttribute("data-active-month"), 2) +
            "/";
        }
      }
    });
  }
  ajxFilters_getActive();

  function ajxFilters_update() {
    is_requeting = true;
    ajxFilters_getActive();
    if (ajxFilterReset) {
      if (activesFilters.length) {
        ffclass.remove(ajxFilterReset.parentNode, "is-active");
      } else if (itemOpen) {
        ffclass.remove(ajxFilterReset.parentNode, "is-active");
      } else {
        ffclass.add(ajxFilterReset.parentNode, "is-active");
      }
    }
    // if (reset) {
    //   if (activesFilters.length) {
    //     ffclass.remove(reset.parentNode, "is-active");
    //   } else {
    //     ffclass.add(reset.parentNode, "is-active");
    //   }
    // }
    ajxFilters_get_query_link();
    // console.log(activesFilters);
    // console.log(query_link);
  }

  function ajxFilters_get_query_link() {
    var ajaxArg = "";
    activesFilters.forEach(function (filter, idx) {
      if (filter.taxonomy && filter.id) {
        ajaxArg += (idx > 0 ? "____" : "") + filter.taxonomy + "__" + filter.id;
      } else if (filter.search) {
        ajaxArg += (idx > 0 ? "____" : "") + "search__" + filter.search;
      } else if (filter.year && filter.month) {
        ajaxArg +=
          (idx > 0 ? "____" : "") +
          "year__" +
          filter.year +
          "____month__" +
          filter.month;
      }
    });
    // console.log(activesFilters);
    // console.log(ajaxArg);
    // console.log(url.archive_projet);
    // console.log(url.archive_post);
    // console.log(structure);
    if (ajxFilter) {
      var filterSiblings = ff.nextAll(ajxFilter);
      if (filterSiblings && filterSiblings.length) {
        filterSiblings.forEach(function (el) {
          el.remove();
        });
      }
      ajxFilter.parentNode.appendChild(loader);
      ff.ajax({
        url: url.ajax,
        json: true,
        params:
          "action=get_filters_query_link&filters=" +
          ajaxArg +
          "&post_type=" +
          ajxFilterPostType +
          "&structure=" +
          structure.slug,
        success: function (data) {
          if (data && data.link) {
            if (support.history && !ajxFilter.closest(".home_section")) {
              history.replaceState({}, "", data.link);
            }
            ajxFilters_load_posts(data.link);
          }
        },
        error: function () {
          is_requeting = false;
          loader.remove();
        },
        // always: function() {
        //   if(postLink) {
        //     ffclass.remove(postLink.parentNode,'has-loader');
        //     ffclass.remove(postLink.parentNode,'has-loader-black');
        //     ffclass.remove(postLink.parentNode,'is-loading');
        //   }
        //   is_requeting = false;
        // },
      });
    }
  }

  function ajxFilters_load_posts(link) {
    link = urldecode(link);
    is_requeting = true;
    ff.ajax({
      url:
        link + (link.indexOf("?") === -1 ? "?" : "&") + "is_ajax=1&load_all=1",
      json: true,
      // params: "action=get_posts&nb=" + postItems.length,
      success: function (data) {
        // console.log(data);
        if (data && data.content) {
          ajxFilter.insertAdjacentHTML("afterend", data.content);
          postsLoader_init(ajxFilter.parentNode);
          fallback_object_fit(ajxFilter.parentNode);
          single_projects(ajxFilter.parentNode);
          accsMobile_init(ajxFilter.parentNode);
          init_videos();
          var reset = $(".filter_reset", main);
          if (reset) {
            reset.addEventListener(
              "click",
              ajxFilter_remove_filter_link,
              false
            );
          }
        }
      },
      always: function () {
        is_requeting = false;
        loader.remove();
      },
    });
  }

  function ajxFilter_init(container) {
    if (reset) {
      reset.addEventListener("click", ajxFilter_remove_filter_link, false);
    }
    if (ajxFilterLinks.length) {
      ajxFilterLinks.forEach(function (el) {
        el.addEventListener("click", ajxFilter_triggering, false);
      });
    }
    if (ajxFilterSelect.length) {
      ajxFilterSelect.forEach(function (el) {
        el.addEventListener("change", ajxFilter_triggering_select, false);
      });
    }
    if (activesFilters.length) {
      ffclass.remove(ajxFilterReset.parentNode, "is-active");
    } else {
      ffclass.add(ajxFilterReset.parentNode, "is-active");
    }
  }
  ajxFilter_init();

  function ajxFilter_triggering(event) {
    event.preventDefault();
    if (is_requeting) return;
    var link = this;
    var is_trigger_link = ffclass.has(link, "filters_trigger");
    var is_reset_link = link.hasAttribute("data-filters-reset");
    if (is_reset_link) {
      ajxFilter_remove_filter_link();
    } else if (is_trigger_link) {
      ajxFilter_trigger_click(link);
    } else {
      ajxFilter_filter_click(link);
    }
  }

  function ajxFilter_triggering_select(event) {
    var selectEl = this;
    var selectValue = this.value;
    var selectItem = selectEl.closest(".filters_i");
    var clickLinkEvent = d.createEvent("HTMLEvents");
    var selectLink = false;

    if (is_requeting || !selectItem) return;

    clickLinkEvent.initEvent("click", true, false);
    if (selectValue && selectValue !== "") {
      if (selectValue.indexOf(url.home) !== -1) {
        selectLink = $(
          '.filters_list a[href="' + selectValue + '"]',
          selectItem
        );
      } else {
        selectLink = $(
          '.filters_list a[data-id="' + selectValue + '"]',
          selectItem
        );
      }
    } else {
      selectLink = $('.filters_trigger[data-active="1"]', selectItem);
    }
    if (selectLink) {
      if (ajxFilter) {
        selectLink.dispatchEvent(clickLinkEvent);
      } else {
        location.href = selectLink.href;
      }
      filters_close();
    }
  }

  function ajxFilter_trigger_click(link) {
    // var link_parent = link.closest(".filters_active");
    // if (ffclass.has(link_parent, "has-active")) {
    // var filters_i = link_parent.closest(".filters_i", body);
    // var filters_list = $(".filters_list", filters_i);
    // var all_link = filters_list.querySelectorAll("[data-id]", filters_list);
    // ffclass.remove(link_parent, "has-active");
    // } else {
    // return;
    // }
    // ajxFilter_remove_filter(link, all_link, link_parent);
    ajxFilters_update();
    // filters_close();
  }

  function ajxFilter_filter_click(link) {
    var is_active = ffclass.has(link, "is-active");
    var link_item = link.closest(".filters_i");
    var link_trigger_item = $(".filters_active", link_item);
    var link_trigger = $(".filters_trigger", link_trigger_item);
    var filters_active = $$(".has-active .filters_trigger", body);
    filters_active.forEach(function (el) {
      var link_parent = el.closest(".filters_active");
      var filters_i = link_parent.closest(".filters_i", body);
      var filters_list = $(".filters_list", filters_i);
      var all_link = $$("[data-id]", filters_list);
      ajxFilter_remove_filter(el, all_link, link_parent);
    });
    if (!is_active) {
      ajxFilter_add_filter(link, link_trigger, link_trigger_item);
      ajxFilters_update();
    }
    // filters_close();
  }

  function ajxFilter_remove_filter(link, all_link, link_parent) {
    // console.log('suppression du filtre');
    var parentItem = link_parent.closest(".filters_i");
    var selectItem = parentItem ? $("select", parentItem) : false;
    if (selectItem) {
      selectItem.value = "";
    }
    ffclass.remove(link_parent, "has-active");
    all_link.forEach(function (el) {
      if (ffclass.has(el, "is-active")) {
        ffclass.remove(el, "is-active");
      }
    });
    // link.innerHTML = link.getAttribute("data-label");
    link.removeAttribute("data-active");
    link.removeAttribute("data-active-id");
    link.removeAttribute("data-active-year");
    link.removeAttribute("data-active-month");
    // link.removeAttribute('data-active-taxo');
  }

  function ajxFilter_remove_filter_link() {
    var filters_list = $$(".filters_i", body);
    filters_list.forEach(function (el) {
      var link_child = $(".filters_active", el);
      var link_triger = $(".filters_trigger", link_child);
      ffclass.remove(link_child, "has-active");
      if ($(".is-active", el)) {
        var link = $(".is-active", el);
        ffclass.remove(link, "is-active");
        link_triger.removeAttribute("data-active");
        link_triger.removeAttribute("data-active-id");
        link_triger.removeAttribute("data-active-year");
        link_triger.removeAttribute("data-active-month");
      }
    });
    ajxFilters_update();
    filters_close();
  }

  function ajxFilter_add_filter(link, link_trigger, link_trigger_item) {
    // console.log("ajout du filtre");
    var is_date_filter = link_trigger.hasAttribute("data-date");
    if (!is_date_filter) {
      link_trigger.setAttribute("data-active-id", link.getAttribute("data-id"));
      // link_trigger.setAttribute('data-active-taxo',link.getAttribute('data-xxx'));
    } else {
      var linkHref = link.href;
      var matchesYearMonth = linkHref.match(/\/([0-9]{4})\/([0-9]{1,2})\/?$/);
      if (matchesYearMonth.length != 3) return;
      link_trigger.setAttribute(
        "data-active-year",
        parseInt(matchesYearMonth[1])
      );
      link_trigger.setAttribute(
        "data-active-month",
        parseInt(matchesYearMonth[2])
      );
    }
    ffclass.add(link_trigger_item, "has-active");
    ffclass.add(link, "is-active");
    // link_trigger.innerHTML = link.innerHTML;
    link_trigger.setAttribute("data-active", 1);
  }

  // function ajxFilter_remove_all_filters() {
  //   var activeEls = $$("[data-active]", ajxFilter);
  //   if (!activeEls.length) return;
  //   activeEls.forEach(function (el) {
  //     ajxFilter_remove_filter(el, el.closest(".filters_active"));
  //   });
  //   ajxFilters_update();
  // }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Recherche
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var search = $("#search");
  var search_btn = $("#search_btn");
  var search_btn_clicking = false;
  if (search && search_btn) {
    search.addEventListener("focus", focus_search, false);
    search.addEventListener("blur", blur_search, false);
    search_btn.addEventListener("mousedown", click_search_btn, false);
  }

  function focus_search(event) {
    ffclass.add(html, "has-searchOpen");
  }

  function blur_search(event) {
    if (!search_btn_clicking) {
      ffclass.remove(html, "has-searchOpen");
    }
  }

  function click_search_btn(event) {
    if (search.value != "") {
      search_btn_clicking = true;
      w.setTimeout(function () {
        search_btn_clicking = false;
      }, 500);
    }
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Recherche en ajax
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function ajax_search() {
    var searchForm = searchInput ? searchInput.closest("form") : false;
    if (!searchForm) return;

    searchForm.addEventListener("submit", ajax_search_submit, false);
  }
  ajax_search();

  function ajax_search_submit(event) {
    event.preventDefault();
    searchInput.setAttribute("data-active-search", searchInput.value);
    ajxFilters_update();
    filters_close();
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Header (menu ou filtres)
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function update_header() {
    adminBar = !adminBar && has_adminBar ? $("#wpadminbar") : adminBar;
    adminBar_h = !adminBar_h && adminBar ? adminBar.clientHeight : adminBar_h;
    if (subHeader) {
      if (
        subHeader.getBoundingClientRect().top <= adminBar_h &&
        (!subHeader_container ||
          subHeader_container.getBoundingClientRect().top +
          subHeader_container.clientHeight >=
          adminBar_h + subHeader_h)
      ) {
        if (!ffclass.has(subHeader, "is-fixed")) {
          ffclass.add(subHeader, "is-fixed");
        }
      } else {
        if (ffclass.has(subHeader, "is-fixed")) {
          ffclass.remove(subHeader, "is-fixed");
          // filters.removeAttribute('style');
        }
      }
    }
  }
  update_header();

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Sliders multiples
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function diapos_init(container) {
    var sliders = $$(".page_section-diapo", container || main);
    if (!sliders.length) return;
    var diapos = diapos_get(sliders);
    diapos_set(diapos);
  }
  diapos_init();

  function diapos_get(sliders) {
    var diaposTemp = [];
    var diapos = [];
    sliders.reverse();
    sliders.forEach(function (slider) {
      if (ffclass.has(slider, "js-is-diapo-added")) return;

      var slidersGroup = [];
      slidersGroup.unshift(slider);
      ffclass.add(slider, "js-is-diapo-added");
      var prevElement = slider.previousElementSibling;
      while (prevElement && ffclass.has(prevElement, "page_section-imgs")) {
        slidersGroup.unshift(prevElement);
        ffclass.add(prevElement, "js-is-diapo-added");
        prevElement = prevElement.previousElementSibling;
      }
      diaposTemp.unshift(slidersGroup);
    });
    diaposTemp.forEach(function (group) {
      var sub_arrays = array_chunk(group, 3);
      sub_arrays.forEach(function (el) {
        diapos.push(el);
      });
    });
    return diapos;
  }

  function diapos_set(diapos) {
    if (!diapos || !diapos.length) return;

    diapos.forEach(function (diapo, idx) {
      diapos_set_single(diapo, idx);
    });
  }

  function diapos_set_single(diapo, diapoID) {
    if (!diapo || !diapo.length || diapo.length < 2) return;

    // var diapo_container = d.createElement('section');
    var diapo_section = diapo[0];
    var diapo_container = $(".slider", diapo[0]);
    var diapo_nav = d.createElement("ul");
    var idx_general = 0;
    ffclass.add(diapo_section, "diapo");
    // ffclass.add(diapo_container,'page_section');
    // ffclass.add(diapo_container,'page_section-imgs');
    ffclass.add(diapo_section, "page_section-diapo");
    ffclass.remove(diapo_section, "js-is-diapo-added");
    ffclass.add(diapo_nav, "diapo_nav");
    // diapo[0].parentNode.insertBefore(diapo_container, diapo[0]);
    diapo.forEach(function (slider, idx) {
      var slides = $$(".slider_i", slider);
      var diapo_nav_item = d.createElement("li");
      var sliderID = "diapo-slider-" + diapoID + "-" + idx;
      var lgd = $(".slider_lgd", slider);
      diapo_nav_item.innerHTML =
        '<a href="#' +
        sliderID +
        '" data-idx="' +
        idx_general +
        '"><img src="' +
        $(".slider_img", slider).getAttribute("src") +
        '" alt="Slider n°' +
        (idx + 1) +
        '"></a>';
      if (lgd) {
        diapo_nav_item.innerHTML +=
          '<p class="srOnly">' + lgd.innerHTML + "</p>";
        lgd.remove();
      }
      if (idx > 0) {
        slides.forEach(function (slide) {
          diapo_container.appendChild(slide);
        });
        slider.remove();
      } else {
        ffclass.add(diapo_nav_item, "is-active");
      }
      idx_general += slides.length;
      diapo_nav.appendChild(diapo_nav_item);
      // var sliderID = 'diapo-slider-'+diapoID+'-'+idx;
      // diapo_nav_item.setAttribute('data-index',idx);
      // ffclass.add(slider,'diapo_i');
      // ffclass.remove(slider,'page_section');
      // ffclass.remove(slider,'page_section-imgs');
      // ffclass.remove(slider,'page_section-diapo');
      // ffclass.remove(slider,'js-is-diapo-added');
      // slider.id = sliderID;
      // diapo_nav_item.innerHTML = '<a href="#'+sliderID+'"><img src="'+$('.slider_img',slider).getAttribute('src')+'" alt="Slider n°'+(idx + 1)+'"></a>';
      // diapo_nav.appendChild(diapo_nav_item);
      // diapo_container.appendChild(slider);
      // if(idx > 0) {
      //   slider.style.display = 'none';
      // } else {
      //   ffclass.add(diapo_nav_item,'is-active');
      // }
    });
    // ff.insertAfter(diapo_nav, diapo_container);
    diapo_section.appendChild(diapo_nav);
    diapos_set_events(diapo_container);
  }

  function diapos_set_events(diapo_container) {
    var links = $$(".diapo_nav a[data-idx]", diapo_container.parentNode);
    links.forEach(function (link, idx) {
      link.addEventListener(
        "click",
        function (event) {
          event.preventDefault();
          diapos_show(diapo_container, link.getAttribute("data-idx"));
        },
        false
      );
    });
  }

  function diapos_show(diapo_container, idx) {
    idx = idx || 0;
    // var diapo_sliders = $$('.diapo_i',diapo_container);
    // var diapo_nav_items = $$('.diapo_nav li',diapo_container);
    // var sliderParent = diapo_sliders[idx];
    // var slider = sliderParent ? $('.slider',sliderParent) : false;
    var flkty = Flickity.data(diapo_container);
    if (flkty) {
      // console.log(flkty);
      idx = Math.floor(
        idx / Math.ceil(flkty.cells.length / flkty.slides.length)
      );
      // console.log(idx);
      flkty.select(idx, true, false);
      diapo_container.focus();
      scroll_to_target(diapo_container);
    }
    // var item = diapo_nav_items[idx];
  }

  // function diapos_set_events(diapo_container) {
  //   var diapo_sliders = $$('.diapo_i',diapo_container);
  //   diapo_sliders.forEach(function(sliderParent,idx) {
  //     var link = $('a[href="#'+sliderParent.id+'"]',diapo_container);
  //     var item = link ? link.closest('li') : false;
  //     var slider = $('.slider',sliderParent);
  //     var flkty = Flickity.data( slider );
  //
  //     if(!link || !item || !slider) return;
  //
  //     link.addEventListener('click',function(event) {
  //       event.preventDefault();
  //       diapos_show(diapo_container, idx);
  //     }, false);
  //
  //     if(flkty) {
  //       // flkty.on( 'select', function() {
  //       //   console.log(flkty.selectedIndexOld,flkty.selectedIndex);
  //       //   // flkty.selectedIndex
  //       //   // flkty.velocity
  //       //   if(flkty.selectedIndex === flkty.slides.length - 1) {
  //       //
  //       //   }
  //       //   if(flkty.selectedIndex === 0) {
  //       //
  //       //   }
  //       // });
  //       flkty.on( 'previous', function() {
  //         if(flkty.selectedIndex === flkty.slides.length - 1) {
  //           var prev_idx = idx === 0 ? (diapo_sliders.length - 1) : (idx - 1);
  //           diapos_show(diapo_container, prev_idx);
  //         }
  //         // console.log('previous',flkty.selectedIndexOld,flkty.selectedIndex);
  //       });
  //       flkty.on( 'next', function() {
  //         if(flkty.selectedIndex === 0) {
  //           var next_idx = idx === (diapo_sliders.length - 1) ? 0 : (idx + 1);
  //           diapos_show(diapo_container, next_idx);
  //         }
  //         // console.log('next',flkty.selectedIndexOld,flkty.selectedIndex);
  //       });
  //     }
  //   });
  // }
  //
  // function diapos_show(diapo_container, idx) {
  //   idx = idx || 0;
  //   var diapo_sliders = $$('.diapo_i',diapo_container);
  //   var diapo_nav_items = $$('.diapo_nav li',diapo_container);
  //   var sliderParent = diapo_sliders[idx];
  //   var slider = sliderParent ? $('.slider',sliderParent) : false;
  //   var flkty = Flickity.data( slider );
  //   var item = diapo_nav_items[idx];
  //
  //   if(!sliderParent || !slider || !item) return;
  //
  //   var mainH = main.clientHeight;
  //   var mainNewH = mainH;
  //   diapo_sliders.forEach(function(el) {
  //     el.style.display = 'none';
  //   });
  //   sliderParent.style.display = '';
  //   mainNewH = main.clientHeight;
  //   ffclass.remove(diapo_nav_items,'is-active');
  //   ffclass.add(item,'is-active');
  //   w.scrollBy(0,mainNewH - mainH);
  //   if(flkty) {
  //     slider.focus();
  //     flkty.resize();
  //     flkty.select( 0, false, true );
  //   }
  //   setTimeout(function() { scroll_to_target(sliderParent); }, 50);
  // }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Sliders
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function slider_init(container) {
    var sliders = $$(".slider, .homeDiapo", container || main);
    if (!sliders.length) return;
    sliders.forEach(function (slider) {
      slider_set(slider);
    });
  }
  slider_init();

  function slider_set(slider) {
    var is_home = ffclass.has(slider, "homeDiapo");
    var is_diapo = !!slider.closest(".diapo");
    var is_full = ffclass.has(slider, "slider-full");
    var is_fade = ffclass.has(slider, "slider-transition-fade");
    var is_autoplay = ffclass.has(slider, "slider-autoplay");
    var no_controls = ffclass.has(slider, "slider-hide-controls");
    var slideSelector = is_home ? ".homeDiapo_i" : ".slider_i";
    var slides = $$(slideSelector, slider);
    var nb_slides = 1;
    if (ffclass.has(slider, "slider-2")) {
      nb_slides = 2;
    } else if (ffclass.has(slider, "slider-3")) {
      nb_slides = 3;
    }
    // is_home ? 1 : (ffclass.has(slider,'slider-2') ? 2 : (ffclass.has(slider,'slider-3') ? 3 : 1));
    if (slides.length <= nb_slides) {
      if (!is_home) ffclass.add(slider, "slider-fix");
      return;
    }
    if (is_home) ffclass.add(slider, "flickity-home");
    var flktySlider = new Flickity(slider, {
      cellSelector: slideSelector,
      setGallerySize: !is_home, //Sets the height of the carousel to the height of the tallest cell
      // resize: !is_home, //Adjusts sizes and positions when window is resized
      resize: true, //Adjusts sizes and positions when window is resized
      cellAlign: "left",
      imagesLoaded: !is_home, //re-positions cells once their images have loaded
      wrapAround: true,
      groupCells: !is_home,
      // adaptiveHeight: !is_home,
      pageDots: false,
      prevNextButtons: !is_full && !no_controls,
      draggable: !no_controls,
      arrowShape:
        "M0 50c0-.8.4-1.7 1-2.3L47.7 1c.6-.6 1.5-1 2.3-1 .8 0 1.7.4 2.3 1l5 5c.6.6 1 1.5 1 2.3 0 .8-.4 1.7-1 2.3L18 50l39.3 39.4c.6.6 1 1.5 1 2.3 0 1-.4 1.7-1 2.3l-5 5c-.6.6-1.5 1-2.3 1-.8 0-1.7-.4-2.3-1L1 52.3c-.6-.6-1-1.5-1-2.3zz",
      dragThreshold: 30,
      fade: is_fade,
      autoPlay: is_autoplay,
      pauseAutoPlayOnHover: false,
    });
    if (no_controls) {
      flktySlider.on("staticClick", function () {
        flktySlider.playPlayer();
      });
    }
    if (!is_home) {
      if (!no_controls) {
        var slider_counter = d.createElement("div");
        ffclass.add(slider_counter, "slider_counter");
        ffclass.add(slider_counter, "t5");
        // slider_counter.innerHTML = '<span>1</span>/<span>'+Math.ceil(slides.length / nb_slides)+' ('+slides.length+')</span>';
        slider_counter.innerHTML =
          "<span>1</span>/<span>" +
          Math.ceil(slides.length / flktySlider.slides.length) +
          "</span>";
        var slider_idx = $("span:first-child", slider_counter);
        var slider_count = $("span:last-child", slider_counter);
        slider.appendChild(slider_counter);
      }
      flktySlider.on("select", function () {
        var flkty = this;
        // console.log(flkty);
        if (!no_controls) {
          // slider_idx.innerHTML = Math.ceil((flktySlider.selectedIndex + 1) / nb_slides)+' ('+(flktySlider.selectedIndex + 1)+')';
          slider_idx.innerHTML = flkty.selectedIndex + 1;
          slider_count.innerHTML = flkty.slides.length;
        }
        if (is_diapo) {
          var nav_diapo = $(".diapo_nav", flkty.element.parentNode);
          var nav_diapo_items = $$(".diapo_nav li", nav_diapo).reverse();
          var nb_by_slide = Math.ceil(flkty.cells.length / flkty.slides.length);
          var realIdx = nb_by_slide * flkty.selectedIndex + (nb_by_slide - 1);
          var oneSelected = false;
          nav_diapo_items.forEach(function (item, idx) {
            var itemIdx = parseInt(
              $("a[data-idx]", item).getAttribute("data-idx")
            );
            if (!oneSelected && realIdx >= itemIdx) {
              ffclass.remove(nav_diapo_items, "is-active");
              ffclass.add(item, "is-active");
              var lgdActual = $(".slider_lgd", flkty.element.parentNode);
              var lgdSlider = $("p.srOnly", item);
              if (lgdActual) {
                lgdActual.remove();
              }
              if (lgdSlider) {
                nav_diapo.insertAdjacentHTML(
                  "beforebegin",
                  '<p class="slider_lgd t5 t5-l txt-grey-3">' +
                  lgdSlider.innerHTML +
                  "</p>"
                );
              }
              oneSelected = true;
            }
          });
        }
      });

      // Slider pleine largeur clic gauche / droite
      // if (is_full) {
      flktySlider.on(
        "staticClick",
        function (event, pointer, cellElement, cellIndex) {
          // console.log(pointer);
          // console.log(pointer.clientX > (windowW / 2));
          if (pointer.clientX > windowW / 2) {
            this.next(true);
          } else {
            this.previous(true);
          }
        }
      );
    }
    // } else {
    //   update_pad_slider_lgd(slider);
    //   flktySlider.on("resize", function () {
    //     update_pad_slider_lgd(slider);
    //   });
    // }
  }

  // function update_pad_slider_lgd(slider) {
  //   var slider_lgds = $$(".homeDiapo_lgd", slider);
  //   var slider_dots = $(".flickity-page-dots", slider);
  //   var dots_w = windowW - slider_dots.getBoundingClientRect().left;
  //   if (dots_w >= windowW) {
  //     dots_w = windowW * 0.2;
  //   }
  //   slider_lgds.forEach(function (el) {
  //     el.style.borderRightWidth = dots_w + "px";
  //   });
  // }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Actu/projets loader
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function postsLoader_init(cont) {
    var postsLoaders = $(".js-post-load", cont || main);
    if (postsLoaders)
      postsLoaders.addEventListener("click", postsLoader_trigger, false);
  }
  postsLoader_init();

  function postsLoader_trigger(event) {
    event.preventDefault();
    var postLink = this;
    var postPage = postLink.closest(".page, .home_section");
    var postGrid = postPage ? $(".grid", postPage) : false;
    // var linkHref = postLink.hasAttribute('href') ? postLink.getAttribute('href') : false;
    var linkHref = postLink.href ? postLink.href : false;
    var linkHrefClean = linkHref
      ? linkHref.replace(/([0-9]{1,})-([0-9]{1,})\/?$/, "")
      : false;
    var linkHrefQuery = linkHrefClean;
    var gridItems = postGrid ? $$(".grid_i", postGrid) : false;
    var nbPerRow = gridItems.length ? get_nb_el_per_row(gridItems) : 0;
    // console.log(nbPerRow)
    if (!postGrid || !linkHref) return;
    linkHrefQuery +=
      gridItems.length + 1 + "-" + (gridItems.length + nbPerRow * 3) + "/";
    if (support.history && !postLink.closest(".home_section"))
      history.replaceState(
        {},
        "",
        linkHrefClean + "1-" + (gridItems.length + nbPerRow * 3) + "/"
      );
    ffclass.add(postLink.parentNode, "has-loader");
    ffclass.add(postLink.parentNode, "has-loader-black");
    ffclass.add(postLink.parentNode, "is-loading");
    is_requeting = true;
    ff.ajax({
      url:
        linkHrefQuery +
        (linkHrefQuery.indexOf("?") === -1 ? "?" : "&") +
        "is_ajax=1",
      json: true,
      // params: 'action=get_posts&nb='+postItems.length,
      success: function (data) {
        // console.log(data);
        if (data && data.content) {
          postGrid.insertAdjacentHTML("beforeend", data.content);
          init_videos(postGrid);
          fallback_object_fit(postGrid);
          single_projects(postGrid);
          accsMobile_init(postGrid);
          if (data.link) postLink.setAttribute("href", data.link);
          else postLink.parentNode.remove();
        }
      },
      always: function () {
        if (postLink) {
          ffclass.remove(postLink.parentNode, "has-loader");
          ffclass.remove(postLink.parentNode, "has-loader-black");
          ffclass.remove(postLink.parentNode, "is-loading");
        }
        is_requeting = false;
      },
    });
  }

  function get_nb_el_per_row(els) {
    var n = 0;
    if (els.length) {
      n = Math.round(
        parseFloat(els[0].parentNode.offsetWidth) /
        parseFloat(els[0].offsetWidth)
      );
    }
    return n;
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Video
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  var plyr_is_ready = false;
  var plyr_js_is_ready = false;
  var plyr_css_is_ready = false;
  var plyr_js_is_loading = false;
  var plyr_css_is_loading = false;
  var next_videos_to_load = [];

  function init_videos(cont) {
    var video_links = $$(".js-video:not([data-js-init])", cont || main);
    video_links.forEach(function (el) {
      el.setAttribute("data-js-init", 1);
      el.addEventListener("click", video_link_trigger, false);
      if (el.hasAttribute("data-autoplay")) {
        var event = d.createEvent("HTMLEvents");
        event.initEvent("click", true, true);
        el.dispatchEvent(event);
      }
    });
  }
  init_videos();

  function plyr_js_loaded() {
    plyr_js_is_ready = true;
    // console.log('JS : '+plyr_js_is_ready);
    check_plyr_is_ready();
  }

  function plyr_css_loaded() {
    plyr_css_is_ready = true;
    // console.log('CSS : '+plyr_css_is_ready);
    check_plyr_is_ready();
  }

  function check_plyr_is_ready() {
    if (plyr_js_is_ready && plyr_css_is_ready) {
      plyr_is_ready = true;
      init_plyr_on_video();
    }
    // console.log('TOUS : '+plyr_is_ready);
    return plyr_is_ready;
  }

  function init_plyr_on_video(link) {
    // console.log('init_plyr');
    var links_to_load = next_videos_to_load;
    if (link) {
      links_to_load.push(link);
    }
    if (Plyr && links_to_load && links_to_load.length) {
      links_to_load.forEach(function (el) {
        if (!el.hasAttribute("data-id")) return;

        // var plyrEl = d.createElement('div');
        // plyrEl.innerHTML = '<div></div>';
        // ff.insertAfter(plyrEl, el);
        // set_plyr_source(plyrEl, el.getAttribute('data-id'), el);
        var plyrPlayer = create_plyr_player(el.getAttribute("data-id"), el);
      });
    }

    next_videos_to_load = [];
  }

  function create_plyr_player(videoId, link) {
    var plyrEl = d.createElement("div");
    var is_autoplay = link.hasAttribute("data-autoplay");
    plyrEl.setAttribute("data-plyr-provider", "vimeo");
    plyrEl.setAttribute("data-plyr-embed-id", videoId);
    ff.insertAfter(plyrEl, link);
    var plyrPlayer = new Plyr(plyrEl, {
      // autoplay: true,
      // clickToPlay: false
      controls: [
        "play",
        "progress",
        "current-time",
        "mute",
        "volume",
        "fullscreen",
      ],
      vimeo: {
        background: is_autoplay,
        muted: is_autoplay,
        dnt: is_autoplay,
        autoplay: is_autoplay,
      },
      loop: {
        active: is_autoplay,
      },
      quality: 720,
    });
    if (is_autoplay) {
      ffclass.add(plyrPlayer.elements.container, "plyr--noControles");
    }
    link.remove();
    plyrPlayer.on("ready", function (event) {
      var plyrContainer = plyrPlayer.elements.container;
      var plyrSlide = plyrContainer.closest(".homeDiapo_i");
      plyrPlayer.play();
      if (plyrSlide) {
        update_plyr_controls(plyrContainer, plyrSlide);
        // debounce
        var update_plyr_controls_debounce = debounce(update_plyr_controls, 250);
        w.addEventListener("resize", update_plyr_controls_debounce);
      }
    });
    return plyrPlayer;
    // plyrPlayer.play();
  }

  function update_plyr_controls(plyrContainer, plyrSlide) {
    var plyrLgd = $(".homeDiapo_lgd", plyrSlide);
    var plyrControls = $(".plyr__controls", plyrContainer);
    if (plyrControls && plyrLgd) {
      plyrControls.style.bottom = plyrLgd.clientHeight + "px";
    }
  }
  // function set_plyr_source(plyrEl, videoId, link) {
  //   var plyrPlayer = Plyr.setup(plyrEl);
  //   console.log(plyrPlayer);
  //   plyrPlayer.source({
  //     type:       'video',
  //     // title:      'Example title',
  //     sources: [{
  //       src:    videoId,
  //       type:   'vimeo'
  //     }]
  //   });
  //   plyrPlayer.play();
  //   link.remove();
  // }

  function video_link_trigger(event) {
    event.preventDefault();
    if (plyr_is_ready) {
      init_plyr_on_video(this);
    } else {
      next_videos_to_load.push(this);
      if (!plyr_js_is_loading) {
        plyr_js_is_loading = true;
        async(url.theme + "js/vendors/plyr-3.7.8.min.js", plyr_js_loaded);
      }
      if (!plyr_css_is_loading) {
        plyr_css_is_loading = true;
        loadCSS(url.theme + "js/vendors/plyr-3.7.8.css", plyr_css_loaded);
      }
    }
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Newsletter
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function newsletter_init() {
    var nl_forms = $$(".js-newsletter", footer);
    if (!nl_forms.length) return;

    nl_forms.forEach(function (el, idx) {
      el.addEventListener("submit", newsletter_submit, false);
    });
  }
  newsletter_init();

  function newsletter_submit(event) {
    event.preventDefault();
    var form = this;
    var nl_input = $(".nl_input", this),
      email_input = $('[name="email"]', nl_input);
    if (email_input && email_input.value && email_input.value != "") {
      var old_message = $$(".nl_message", form);
      if (old_message.length) {
        old_message.forEach(function (el, idx) {
          el.remove();
        });
      }
      ff.ajax({
        url: url.ajax,
        json: true,
        params:
          "action=newsletter&newsletter_subscribe=1&email=" + email_input.value,
        success: function (data) {
          if (data) {
            nl_input.insertAdjacentHTML("afterend", data.text_html);
            if (!data.error) {
              email_input.value = "";
            }
          }
        },
        error: function () {
          form.submit();
        },
      });
    }
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Navigation vers un projet (sauvegarde des filtres)
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function single_projects(cont) {
    var projectLinks = $$(
      "[data-single-project]:not([data-js-init])",
      cont || main
    );
    projectLinks.forEach(function (el, idx) {
      el.setAttribute("data-js-init", 1);
      el.addEventListener("click", single_projects_trigger, false);
      el.addEventListener("contextmenu", single_projects_trigger, false);
    });
  }
  single_projects();

  // var value_cookie = cookies('atelier_projects_filters');
  // console.log(value_cookie);

  function single_projects_trigger(event) {
    // event.preventDefault();
    // console.log(activesFilters);
    var link = this;
    if (activesFilters && activesFilters.length) {
      cookies({
        atelier_projects_filters: {
          id: parseInt(link.getAttribute("data-single-project")),
          filters: activesFilters,
        },
      });
    } else {
      cookies({ atelier_projects_filters: null });
    }
  }

  // cookies({ name_cookie: '42' }); // set
  // cookies({ name_cookie: null }); // remove
  // var value_cookie = cookies('name_cookie'); //read

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Keys event
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  d.addEventListener("keydown", function (e) {
    if (e.keyCode == 27) {
      menu_close();
    }
  });

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //On resize
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function onResize() {
    update_dimensions();
  }
  w.addEventListener("resize", onResize, false);

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //On scroll
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  // function onScroll() {
  //   update_scroll();
  //   update_header();
  // }
  // w.addEventListener('scroll', onScroll, false);
  var ticking = false;
  function onScroll() {
    update_scroll();
    requestTick();
  }
  w.addEventListener("scroll", onScroll, false);

  function requestTick() {
    if (!ticking) {
      ticking = true;
      w.requestAnimationFrame(trigger_scroll);
    }
  }

  function trigger_scroll() {
    update_header();
    nav_slide_update();
    ticking = false;
  }

  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //Bloc newsletter Parrallax
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  //■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
  function parrallax() {
    var newsletter = $(".footer_nl", body);
    var spacer = $(".footer_spacer", body);
    spacer.style.height = newsletter.offsetHeight + "px";
  }
  parrallax();
})(window);
